<template>
  <div>
    <app-detail-header :show-toggle="false"
      :show-delete="false"
      :show-save="false"
      :show-print="false"
      :show-new="false"
      :show-cancel="false" />
    <div class="tile is-parent">
      <article class="tile is-child box detail-page-tile">
        <label class="label">Quote No.</label>
        <div class="field has-addons">
          <div class="control is-expanded">
            <input class="input is-uppercase"
              v-model="quoteNo"
              @input="searchQuote"
              placeholder="Start typing to search..."
              v-focus-inserted
              :disabled="isUploading">
            <span v-if="validQuote"
              class="help is-success">Valid Quote No.</span>
            <span v-else-if="quoteNo.length > 0"
              class="help is-danger">Invalid Quote No.</span>
          </div>
          <div class="control">
            <a class="button is-primary"
              @click="dropzoneRemoveAllFiles"
              :disabled="isUploading || !validQuote">
              <span class="icon">
                <i class="mdi mdi-close mdi-18px" />
              </span>
              <span>Clear</span>
            </a>
          </div>
          <div class="control">
            <a class="button is-success"
              @click="upload"
              :disabled="isUploading || !validQuote">
              <span class="icon">
                <i class="mdi mdi-upload mdi-18px" />
              </span>
              <span>Upload</span>
            </a>
          </div>
        </div>
        <div class="field"
          v-show="isDropzoneActive && isMobile.any()">
          <div class="control">
            <div class="select">
              <select v-model="dropzoneOptions.capture">
                <option :value="mobileImageSourceType.Gallery">From Gallery</option>
                <option :value="mobileImageSourceType.Camera">From Camera</option>
              </select>
            </div>
          </div>
        </div>
        <vue-dropzone v-if="validQuote && isDropzoneActive"
          ref="quoteImageDropzone"
          id="dropzone"
          :options="dropzoneOptions"
          @vdropzone-mounted="dropzoneMounted"
          @vdropzone-file-added="dropzoneFileAdded"
          @vdropzone-success="dropzoneSuccess"
          @vdropzone-error-multiple="dropzoneErrorMultiple"
          @vdropzone-sending="dropzoneSending"
          @vdropzone-total-upload-progress="dropzoneTotalUploadProgress"
          @vdropzone-complete-multiple="dropzoneCompleteMultiple"
          @vdropzone-queue-complete="dropzoneQueueComplete" />
      </article>
    </div>
  </div>
</template>

<script>
import mimeTypes from 'mime-types'
import VueDropzone from '@/components/VueDropzone'
import AppDetailHeader from '@/components/AppDetailHeader'
import { FocusInserted } from '@/components/directives'
import QuoteService from '@/views/quote/QuoteService'
import { guidValidator } from '@/components/validators'
import Guid from '@/components/Guid'
import _debounce from 'lodash.debounce'
import CommonApiService from '@/services/common'
import { isMobile } from '@/components/utils/helpers'
import { MobileImageSourceType } from '@/enums'

export default {
  name: 'ImageUploader',
  directives: {
    FocusInserted
  },
  components: {
    VueDropzone,
    AppDetailHeader
  },
  props: {},
  data() {
    return {
      quoteNo: '',
      quoteId: '',
      isDropzoneActive: false,
      isClearAllActive: false,
      isUploading: false,
      queuedFileCount: 0,
      dropzoneOptions: {
        url: `${process.env.VUE_APP_WEBAPI_URI}/quotes/images/upload/${this.quoteId}/${this.mainQuoteNo}/${this.subQuoteNo}/${this.categoryId}`,
        dictDefaultMessage: 'Drop files here or click to upload',
        autoProcessQueue: false,
        thumbnailWidth: 200,
        thumbnailHeight: 200,
        maxFilesize: 10,
        resizeWidth: 800,
        acceptedFiles: 'image/*',
        capture: 'image/',
        headers: {
          Authorization: ''
        },
        accept: function(file, done) {
          const mimeType = file.type
          if (mimeType !== mimeTypes.contentType('jpeg')) {
            this.options.resizeMimeType = mimeTypes.contentType('jpeg')
            this.options.resizeQuality = 0.9
            done()
            return
          }
          done()
        },
        renameFile(file) {
          const extension = file.name.split('.').pop()
          if (extension !== 'jpg' || extension !== 'jpeg') {
            return `${file.name.substr(0, file.name.lastIndexOf('.'))}.${mimeTypes.extension(mimeTypes.contentType('jpeg'))}`
            // return file.name.substr(0, file.name.lastIndexOf('.')) + '.jpg'
          } else {
            return file.name
          }
        }
      },
      categoryId: null
    }
  },
  validations: {
    quoteId: {
      validGuid: guidValidator
    }
  },
  computed: {
    validQuote() {
      return this.$v.quoteId.$dirty && !this.$v.quoteId.$error
    },
    mainQuoteNo() {
      const quoteNoArray = this.quoteNo.split('/')
      return quoteNoArray[0].toUpperCase()
    },
    subQuoteNo() {
      const quoteNoArray = this.quoteNo.split('/')
      return quoteNoArray.length > 1 ? quoteNoArray[1] : '0'
    },
    isMobile() {
      return isMobile
    },
    mobileImageSourceType() {
      return MobileImageSourceType
    }
  },
  watch: {
    'dropzoneOptions.capture': function (newVal, oldVal) {
      // Reload vue-dropzone component here
      if (this.isDropzoneActive === true) {
        this.isDropzoneActive = false
        this.$nextTick(() => {
          this.isDropzoneActive = this.validQuote
        })
      }
    }
  },
  created() {
    this.dropzoneOptions.capture = isMobile.any() ? this.mobileImageSourceType.Gallery : this.mobileImageSourceType.Camera
    this.getGeneralImageCategory()
    this.dropzoneOptions.headers.Authorization = `Bearer ${CommonApiService.getAuthToken()}`
  },
  mounted() {},
  methods: {
    searchQuote: _debounce(async function() {
      this.$v.quoteId.$touch()
      if (this.quoteNo) {
        this.quoteId = await QuoteService.getQuoteIdByNo(this.quoteNo)
        this.isDropzoneActive = this.validQuote
        this.dropzoneOptions.url = `${process.env.VUE_APP_WEBAPI_URI}/quotes/images/upload/${this.quoteId}/${this.mainQuoteNo}/${this.subQuoteNo}/${this.categoryId}`
      } else {
        this.quoteId = Guid.empty()
        this.isDropzoneActive = false
      }
    }, 500),
    upload() {
      if (this.$refs.quoteImageDropzone.getAcceptedFiles().length > 0) {
        this.queuedFileCount = this.$refs.quoteImageDropzone.getQueuedFiles().length
        this.$refs.quoteImageDropzone.processQueue()
      }
    },
    dropzoneMounted() {
      // console.log(this.$refs.quoteImageDropzone.getAcceptedFiles())
    },
    dropzoneFileAdded() {
      this.isClearAllActive = true
      // this.isUploading = true
    },
    dropzoneSuccess(file, response) {
      // console.log(file)
      this.images = []
    },
    dropzoneErrorMultiple(files, message, xhr) {},
    dropzoneSending(file, xhr, formData) {
      // console.log(file)
      // for (var value of formData.values()) {
      //   console.log(value)
      // }
    },
    dropzoneCompleteMultiple(files) {
      // console.log(files.count)
    },
    dropzoneQueueComplete(files, xhr, formData) {
      this.isUploading = false
      this.$notification.openNotificationWithType('success', 'Upload images', `${this.queuedFileCount} images uploaded`)
      this.queuedFileCount = 0
    },
    dropzoneTotalUploadProgress(totaluploadprogress, totalBytes, totalBytesSent) {
      this.isUploading = totaluploadprogress < 100
      // console.log(totaluploadprogress)
    },
    dropzoneRemoveAllFiles() {
      if (this.isUploading || !this.validQuote) {
        return
      }

      this.$refs.quoteImageDropzone.removeAllFiles()
      this.isClearAllActive = false
    },
    async getGeneralImageCategory() {
      this.categoryId = await QuoteService.getGeneralImageCategory()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/style/mixins/inputs/placeholder';

input.is-uppercase {
  text-transform: uppercase;
  @include reset-placeholder-text-transform;
}

</style>